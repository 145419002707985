import React from "react"
import { graphql, StaticQuery } from "gatsby"

// Pages
// import PerPageLayout from "../components/per-page-layout"
import LayoutV2 from "../components/v2/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/v2.css"

// Partials
import {
  Section,
  BlogList, 
  BlogListItem
} from "../components/partials/base"


const InsightsPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title;
    const insights = data.allMarkdownRemark.edges.filter((post, index) =>{
        return post.node.frontmatter.posttype === "insight"
    }).map((insight) =>{
        const {node} = insight;
        return {
            "thumbnail" : node.frontmatter.thumbnail,
            "title" : node.frontmatter.title,
            "description" : node.frontmatter.description,
            "author" : `By ${node.frontmatter.author} - ${node.frontmatter.date}`,
            "link" : `insight${node.fields.slug}`
        }
    });

    const PER_PAGE = 10, NUMBER_OF_PAGES = Math.ceil(insights.length/PER_PAGE);

    return (
        <LayoutV2 location={location} title={siteTitle} headerActive={`Insights`}>
        <SEO title={`Insights`} keywords={[]} />

        <article className="post-content page-template no-image animated fadeIn">
            <div className="post-content-body">
            <div className="row">
                <div className="col-12">
                <h1 className="hero-title-center">Distributed Insights</h1>
                </div>
            </div>

            <Section sectionName={`1 of ${NUMBER_OF_PAGES} pages`} sectionLabelRight={`01`} sectionClassName="insights-homepage-section">
                <BlogList>
                    {
                        insights.map(({thumbnail, title, description, author, link}) =>{
                            return <BlogListItem 
                                thumbnail={thumbnail}
                                title={title}
                                description={description}
                                author={author}
                                link={link}
                            ></BlogListItem>
                        })
                    }
                </BlogList>
            </Section>
            </div>
        </article>
        </LayoutV2>
    )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 1000
    ) {
        edges {
            node {
                fields {
                    slug
                }
                frontmatter {
                    title
                    posttype
                    author
                    date(formatString: "MMMM DD, YYYY")
                    description
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 1024, maxHeight: 550) {
                                ...GatsbyImageSharpFluid_noBase64
                                presentationWidth
                            }
                        }
                    }
                }
            }
        }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <InsightsPage location={props.location} data={data} {...props} />
    )}
  />
)
